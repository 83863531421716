module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Story Wars","short_name":"Story Wars","start_url":"/","background_color":"#F05F5F","theme_color":"#F05F5F","display":"minimal-ui","icon":"src/images/storywars.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7853761ac6e46726793dd4cb59ee9e49"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
